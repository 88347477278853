<template>
  <div
    v-if="show"
    ref="confimration_modal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              Statutory Report
            </span>
            <a
              class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              @click="$emit('close')"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
          <span class="modal__header__subtitle text--capital"> </span>
        </div>
        <div class="modal__content">
          <div class="modal__body">
            <div class="account_item_head">
              <p class="account_item_head_text text--white">
                select report type
              </p>
            </div>

            <div class="form__item mt-5" style="display: flex; width: 100%">
              <div class="mr-2" style="width: 100%">
                <label class="form__label text--black text--capital mb-3"
                  >select type</label
                >
                <select class="select select--lg" v-model="reportType" required>
                  <option value="" disabled selected>Select Type</option>
                  <option value="Guarantors">Guarantors</option>
                  <option value="Credit">Credit</option>
                  <option value="Individual Borrower">
                    Individual Borrower
                  </option>
                  <option value="PAR Report">PAR Report</option>
                </select>
              </div>
            </div>

            <div
              class="form__item mt-5"
              style="display: flex; width: 100%"
              v-if="reportType === 'PAR Report'"
            >
              <div class="mr-2" style="width: 50%">
                <label class="form__label text--black text--capital mb-3"
                  >select start date</label
                >
                <input
                  type="date"
                  class="input form__input form__input--lg r-font"
                  placeholder="Enter value"
                  v-model="startDate"
                />
              </div>

              <div class="ml-2" style="width: 50%">
                <label class="form__label text--black text--capital mb-3"
                  >select end date</label
                >
                <input
                  type="date"
                  class="input form__input form__input--lg r-font"
                  placeholder="Enter value"
                  :min="startDate"
                  v-model="endDate"
                />
              </div>
            </div>

            <div class="loader" v-if="isLoading">
              <div class="loader-text"></div>
            </div>
            <div class="d-flex">
              <button
                class="button form__button form__button--sm w-50 mr-2"
                @click="getTransactions"
                :disabled="!reportType"
                v-if="!isLoading"
              >
                export
              </button>
              <button
                class="button form__button form__button--sm w-50 mr-2"
                @click="getTransactions"
                :disabled="true"
                v-if="isLoading"
              >
                <div class="spinner-border text-light" role="status"></div>
              </button>
              <button
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="$emit('close')"
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as XLSX from "xlsx";
import ApiService from "@/core/services/api.loan.service";
export default {
  name: "ViewTransaction",
  props: {
    show: Boolean,
    close: Function,
    loanKey: String,
    // accountDetails: [Array, Object],
  },
  data() {
    return {
      reportType: "",
      startDate: "",
      endDate: "",
      isLoading: false,
      reportData: [],
      currentDate: moment().format("DD MMMM, YYYY"),
      parReportListArrange: [],
    };
  },
  mounted() {},
  methods: {
    getTransactions() {
      this.isLoading = true;
      const url = `LoanAccount/creditbureaureport?reportType=${this.reportType}&startDate=${this.startDate}&endDate=${this.endDate}`;
      ApiService.get(url)
        .then((response) => {
          this.processDownload(response.data);
        })
        .catch((err) => {
          this.isLoading = false;
          this.$emit("statementAlert", {
            type: "error",
            message: err.response.data.message,
          });
        });
    },
    async processDownload(data) {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Bureau Report Download",
        Subject: "Bureau Report Excel Sheet",
        Author: "Royal",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("BureauReport");

      const strArray = this.reportType.split(" ");
      const str = `${strArray[0].toLocaleLowerCase()}${
        strArray[1] ? strArray[1] : ""
      }`;

      let sheetData = await this.getSheetHeaderType(data, str);

      if (!this.reportData || !this.reportData.length) {
        this.isLoading = false;
        this.$emit("statementAlert", {
          type: "error",
          message: "No data found for the selected report type.",
        });
        return;
      }

      this.reportData.forEach((item) => {
        let new_arr = [];
        let array_key = [];

        Object.keys(item).map((key) => {
          const itemVal = item[key] ? item[key] : "";
          new_arr.push(itemVal);
          array_key.push({ key, value: itemVal });
        });

        if (this.reportType === "PAR Report") {
          array_key.forEach((data) => {
            if (data.key == "accountOfficer") {
              const findOfficer = this.parReportListArrange.find(
                (x) => x.type === "Account Officer"
              );
              new_arr.splice(findOfficer.accountOfficerIndex, 1);
              new_arr.splice(findOfficer.branchIndex, 0, `${data.value}`);
            }

            if (data.key == "tenor") {
              const findTenor = this.parReportListArrange.find(
                (x) => x.type === "Tenor"
              );
              new_arr.splice(findTenor.tenorIndex, 1);
              new_arr.splice(findTenor.expireDateIndex + 1, 0, `${data.value}`);
            }

            if (data.key == "status") {
              const findStatus = this.parReportListArrange.find(
                (x) => x.type === "Status"
              );
              new_arr.splice(findStatus.statusIndex - 1, 1);
              new_arr.splice(findStatus.addressIndex - 1, 0, `${data.value}`);
            }
          });
        }

        sheetData.push(new_arr);
      });

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["BureauReport"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },

    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const name = this.reportType.replace(" ", "_").toLowerCase();
      const month = moment().format("MMMM").toLowerCase();

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Royal_bank_${name}_bureau_${month}_report.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      this.$emit("statementAlert", {
        type: "success",
        message: "Report downloaded successfully.",
      });
      this.isLoading = false;
      this.reportType = "";
      this.reportData = [];
      this.startDate = "";
      this.endDate = "";
      this.$emit("close");
    },
    getSheetHeaderType(data, type) {
      let excelHeader = [];

      this.reportData = data[type];
      if (data[type] && data[type].length) {
        Object.keys(data[type][0]).map((key) => {
          excelHeader.push(this.amendSentence(key));
        });
      }

      if (this.reportType === "PAR Report") {
        const accountOfficerIndex = excelHeader.indexOf("Account Officer");
        const branchIndex = excelHeader.indexOf("Branch");
        excelHeader.splice(accountOfficerIndex, 1);
        excelHeader.splice(branchIndex, 0, "Account Officer");

        const tenorIndex = excelHeader.indexOf("Tenor");
        const expireDateIndex = excelHeader.indexOf("Expiry Date");
        excelHeader.splice(tenorIndex, 1);
        excelHeader.splice(expireDateIndex + 1, 0, "Tenor");

        const statusIndex = excelHeader.indexOf("Status");
        const addressIndex = excelHeader.indexOf("Address");
        excelHeader.splice(statusIndex, 1);
        excelHeader.splice(addressIndex, 0, "Status");

        this.parReportListArrange.push({
          accountOfficerIndex,
          branchIndex,
          type: "Account Officer",
        });
        this.parReportListArrange.push({
          tenorIndex,
          expireDateIndex,
          type: "Tenor",
        });
        this.parReportListArrange.push({
          statusIndex,
          addressIndex,
          type: "Status",
        });

        const nubanIndex = excelHeader.indexOf("Nuban");
        excelHeader[nubanIndex] = "NUBAN";

        const bvnIndex = excelHeader.indexOf("Bvn");
        excelHeader[bvnIndex] = "BVN";

        const tinIndex = excelHeader.indexOf("Tin");
        excelHeader[tinIndex] = "TIN";

        const totalOut = excelHeader.indexOf("Total Out Standing");
        excelHeader[totalOut] = "Total Outstanding";

        const feeOut = excelHeader.indexOf("Fee Out Standing");
        excelHeader[feeOut] = "Fee Outstanding";

        const overDueOD = excelHeader.indexOf("Over Due O D Int");
        excelHeader[overDueOD] = "Overdue OD Int.";
      }

      return [excelHeader];
    },
    amendSentence(sstr) {
      let str = sstr.split("");
      let newSentence = "";

      // Traverse the string
      for (let i = 0; i < str.length; i++) {
        // Convert to lowercase if its an uppercase character
        if (
          str[i].charCodeAt() >= "A".charCodeAt() &&
          str[i].charCodeAt() <= "Z".charCodeAt()
        ) {
          str[i] = String.fromCharCode(str[i].charCodeAt() + 32);

          // Add space before it if its an uppercase character
          if (i != 0) newSentence = newSentence + " ";

          // Add the character to the variable newSentence
          newSentence = newSentence + str[i].toUpperCase();
        } else {
          // if lowercase character then just print
          if (i == 0) newSentence = newSentence + str[i].toUpperCase();
          else newSentence = newSentence + str[i];
        }
      }

      return newSentence;
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>

<style scoped>
.overlap {
  word-wrap: break-word;
  width: 90%;
}

.loader {
  width: 100%;
}
.loader-text {
  width: 95%;
  margin-bottom: 0;
  /* margin: auto; */
}
.loader-text:after {
  content: "Downloading report, please wait";
  color: red;
  font-size: 11px;
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Downloading report, please wait.";
  }
  25% {
    content: "Downloading report, please wait..";
  }
  50% {
    content: "Downloading report, please wait...";
  }
  75% {
    content: "Downloading report, please wait....";
  }
  100% {
    content: "Downloading report, please wait.....";
  }
}
</style>
