var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main"},[_c('div',{staticClass:"toaster show"},[(_vm.alert.show)?_c('div',{staticClass:"toaster__container",class:{
        'toaster--success': _vm.alert.status === 'success',
        'toaster--error': _vm.alert.status === 'fail',
        'toaster--warn': _vm.alert.status === 'warn',
      }},[_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"fa fa-times",on:{"click":function($event){_vm.alert.show = false}}})]),_c('div',[_c('span',{staticClass:"d-block text--small text--600 text--white"},[_vm._v(_vm._s(_vm.alert.title))]),_c('span',{staticClass:"d-block text--tiny text--400 text--white"},[_vm._v(_vm._s(_vm.alert.description))])])]):_vm._e()]),_c('KTHeader'),_c('div',{class:[_vm.pathName, 'page']},[_c('div',{staticClass:"container page__inner"},[_c('KTAside',{class:{ 'p-0': _vm.layout === 'STANDARD' },style:({ width: _vm.sideBarWidth + '%', padding: _vm.sideBarWidth })}),_c('div',{class:{
          page__content: _vm.layout === 'STANDARD',
          'p-0': _vm.layout === 'FULL',
          'page__content--wider': _vm.layout === 'FULL',
        }},[_c('router-view')],1)],1)]),_c('RightAside',{on:{"showReport":_vm.showReport}}),_c('bureauReport',{attrs:{"show":_vm.showBureauReport},on:{"close":_vm.closeReportModal,"statementAlert":_vm.statementAlert}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }