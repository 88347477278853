<template>
  <main class="main">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
    <KTHeader></KTHeader>
    <div :class="[pathName, 'page']">
      <div class="container page__inner">
        <KTAside
          :style="{ width: sideBarWidth + '%', padding: sideBarWidth }"
          :class="{ 'p-0': layout === 'STANDARD' }"
        ></KTAside>
        <div
          :class="{
            page__content: layout === 'STANDARD',
            'p-0': layout === 'FULL',
            'page__content--wider': layout === 'FULL',
          }"
        >
          <router-view />
        </div>
      </div>
    </div>
    <RightAside @showReport="showReport" />
    <bureauReport
      :show="showBureauReport"
      @close="closeReportModal"
      @statementAlert="statementAlert"
    />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import KTHeader from "@/view/layout/header/Header.vue";
import RightAside from "@/view/layout/aside/RightAside.vue";
import KTAside from "@/view/layout/aside/Aside.vue";
import bureauReport from "@/view/pages/loans/account_details/components/bureau_report.vue";
export default {
  name: "FullLayout",
  data: function () {
    return {
      layout: "STANDARD",
      sideBarWidth: 25,
      showBureauReport: false,
      alert: {
        show: false,
        status: "",
        title: "",
        description: ``,
      },
    };
  },
  components: {
    KTHeader,
    RightAside,
    KTAside,
    bureauReport,
  },
  beforeMount() {
    // show page loading
    // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    // initialize html element classes
    // HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    if (this.$route.hasOwnProperty("meta")) {
      if (this.$route.meta.hasOwnProperty("layout")) {
        this.layout = this.$route.meta.layout;
        if (this.$route.meta.layout === "FULL") {
          this.sideBarWidth = 0;
        } else {
          this.sideBarWidth = 25;
        }
      } else {
        this.layout = "STANDARD";
        this.sideBarWidth = 25;
      }
    }
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      // this.$router.push({ name: "login" });
    }
    // window.location.reload()

    // Simulate the delay page loading
    // setTimeout(() => {
    //   // Remove page loader after some time
    //   this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    // }, 2000);
  },
  beforeRouteUpdate(to, from, next) {
    if (to.hasOwnProperty("meta")) {
      if (to.meta.hasOwnProperty("layout")) {
        this.layout = to.meta.layout;
        if (to.meta.layout === "FULL") {
          this.sideBarWidth = 0;
        } else {
          this.sideBarWidth = 25;
        }
      } else {
        this.layout = "STANDARD";
        this.sideBarWidth = 25;
      }
    }
    next();
  },
  methods: {
    showReport(value) {
      this.showBureauReport = value;
    },
    closeReportModal() {
      this.showBureauReport = !this.showBureauReport;
    },
    statementAlert({ type, message }) {
      if (type === "error") {
        this.alertError(message);
      }
      if (type === "success") {
        this.alertSuccess(message);
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    pathName() {
      return this.$route.name;
    },

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      // return !!this.layoutConfig("aside.self.display");
      return localStorage.setItem("hideSideMenu");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
